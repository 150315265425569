@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500&display=swap');

.heybotnew-bg{
    height: 100vh;
    width: 100%;
    background: #DCF6FF;
    font-family: 'Montserrat', sans-serif !important;
    /* background: linear-gradient(90deg, #90ee90 0%, #50c878 35%, #2aaa8a 100%); */
}
.heybotnew-box{
    border-radius: 24px;
}
.heybotnew-nav{
    width: 20% !important;
}
.heybotnew-main{
    width: 80% !important;
}
.heybotnew-border{
    border-radius: 8px !important;
    border: 1px solid #D9D9D9;
    padding: 0.5rem 1rem !important;
    width: 100%;
    display: flex;
    align-items: center;
}
.heybotnew-accordion-header-user>.accordion-button{
    font-size: 0.75rem !important;
    font-weight: 500 !important;
    max-height: 2.25rem !important;

}

.heybotnew-accordion{
    --bs-accordion-btn-focus-border-color : none !important;

}

.heybotnew-accordion-user{
    --bs-accordion-bg: #DCF6FF !important;
    padding: 0.5rem 1rem !important;

}
.heybotnew-accordion-header-user>.accordion-button{
    padding: 0 !important;
}
.heybotnew-accordion-header-user>.accordion-button:focus{
    border-color: none !important;
    --bs-accordion-btn-focus-border-color : none !important;
}
.heybotnew-accordion-header-user>.accordion-button:not(.collapsed),.heybotnew-accordion-header>.accordion-button:not(.collapsed){
    background-color: transparent !important;
}

.heybotnew-user-btn{
    cursor: pointer;
    border-radius: 8px !important;
    background-color: #DCF6FF !important;
    padding: 0.5rem 1rem !important;
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
}
.heybotnew-border-btn{
    cursor: pointer;
    border-radius: 8px !important;
    border: 1px solid #D9D9D9;
    padding: 0.5rem 1rem !important;
    height: 2.25rem !important;
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
}
.heybotnew-border-btn-hover:hover{
    background:#f7f7f7 !important;
}
.heybotnew-secondary-btn{
    cursor: pointer;
    background: #F2F2F2;
    border-radius: 8px !important;
    border: 0;
    padding: 0.75rem !important;
    height: 2.25rem !important;
    width: 2.25rem !important;
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
}
.heybotnew-secondary-btn:hover{
    background: #dfdfdf;
}
.heybotnew-primary-btn{
    cursor: pointer;
    background: #362f2e;
    color: white;
    border-radius: 8px !important;
    border: 0;
    padding: 0.5rem 1rem !important;
    height: 2.25rem !important;
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    transition: background 300ms;
}
.heybotnew-primary-btn:hover{
    background: #0e0c0c;
}
.heybotnew-accordion-header>button{
    border-radius: 24px !important;
    padding: 0 !important;
    font-weight: 600;
    font-size: 0.875rem;
}
.heybotnew-theme-light{
    background: white !important;
    color: black !important;
}
.heybotnew-theme-dark{
    background: #362f2e !important;
    color: white !important;
}
.heybotnew-offcanvas{
    --bs-offcanvas-width:300px !important;
}
.heybotnew-login-image{
    width: 3.5rem;
    height: 3.5rem;
}
.heybotnew-logo-image{
    height: 3rem;
}
.heybotnew-new-btn{
    background: #ABE4F8;
    border-radius: 1rem;
    align-self: center;
}
.heybotnew-chat-image{
    max-width: 20rem;
    max-height: 20rem;
}
.heybotnew-image{
    width: 1.5rem;
    height: 1.5rem;
}
.heybotnew-image-small{
    min-width: 0.75rem;
    min-height: 0.75rem;
    max-width: 0.75rem;
    max-height: 0.75rem;
}
.cursor-pointer{
    cursor: pointer;
}
.heybotnew-popover{
    margin-left: -2.45rem;
    margin-top: 1rem;
    --bs-popover-bg: none !important;
}
.heybotnew-popover>.popover-arrow{
    margin-left: 2.5rem;
}

.heybotnew-button{
    border-radius: 0.6rem !important;
    -webkit-box-shadow:  2px 2px 4px 2px rgba(55, 55, 55, 0.1);
    -moz-box-shadow:  2px 2px 4px 2px rgba(55, 55, 55, 0.1);
    box-shadow:  2px 2px 4px 2px rgba(55, 55, 55, 0.1) !important;
}
.heybotnew-general-btn{
    min-width: 15rem !important;
    min-height: 3rem !important;
    align-self: center;
    justify-self: center;
    font-weight: 600 !important;
}

.heybotnew-list{
    background-color: transparent !important;
    border: 0 !important;
    font-size: 0.75rem;
}
.heybotnew-session-container{
    min-width: 10rem;
    max-width: 13rem;
}
.heybotnew-list.heybotnew-theme-light{
    -webkit-box-shadow:  1px 1px 2px 1px rgba(59, 59, 59, 0.3);
    -moz-box-shadow:  1px 1px 2px 1px rgba(59, 59, 59, 0.3);
    box-shadow:  1px 1px 2px 1px rgba(59, 59, 59, 0.3);
}
.heybotnew-list.heybotnew-theme-dark{
    -webkit-box-shadow:  1px 1px 2px 1px rgba(59, 59, 59, 0.3);
    -moz-box-shadow:  1px 1px 2px 1px rgba(59, 59, 59, 0.3);
    box-shadow:  1px 1px 2px 1px rgba(59, 59, 59, 0.3);
}

.heybotnew-input{
    border-radius: 1rem !important;
    border: solid 1px #EBEBEB;
    box-shadow: 0px 12px 36px 0px rgba(0, 0, 0, 0.08);

    height: 3.75rem;
    word-wrap: break-word;
}
.heybotnew-history-input{
    font-size: 0.875rem !important;
}
.heybotnew-history-input::placeholder{
    font-size: 0.875rem !important;
}
.heybotnew-send-btn{
    height: 2.5rem;
    width: 2.5rem;
}
.theme-light.heybotnew-input{
    background: white !important;
    color: black !important;
}
.theme-dark.heybotnew-input{
    background: black !important;
    color: white !important;
}
.heybotnew-bot-msg{
    background-color: #362f2e;
    border-radius: 0.6rem;
    border-top-left-radius: 0;
    color: white !important;
}
.heybotnew-user-msg{
    background-color: #FFF0EE;
    border-radius: 0.6rem;
    border-top-right-radius: 0;
    color: black !important;
}
.heybotnew-divider{
    height: 0.5rem;
    width: 0.1rem;
    background-color: #362f2e;
    opacity: 0.6;
}
.heybotnew-divider-horizontal{
    height: 0.1rem;
    width: 15rem;
    background-color: #362f2e;
    opacity: 0.4;
}
.heybotnew-navbar-container{
    height: 100%;
}
.heybotnew-share-button{
    background-color: #362f2e !important;
    color: white !important;
    border: none !important;
    border-radius: 0.6rem !important;
    -webkit-box-shadow:  2px 2px 4px 2px rgba(55, 55, 55, 0.1);
    -moz-box-shadow:  2px 2px 4px 2px rgba(55, 55, 55, 0.1);
    box-shadow:  2px 2px 4px 2px rgba(55, 55, 55, 0.1);
}
.heybotnew-modal>.modal-dialog{
    justify-content: center !important;
}
.heybotnew-modal>.modal-dialog>.modal-content{
    height: 95vh !important;
    border-radius: 24px !important;
    min-width: 50vw;
}
.pricing-modal>.modal-dialog{
    justify-content: center !important;
}
.pricing-modal>.modal-dialog>.modal-content{
    height: 95vh !important;
    border-radius: 24px !important;
    min-width: 90vw;
}
.pricing-modal.modal{
    --bs-modal-margin: none !important;
    display: flex !important;
    align-items: center !important;
}
.heybotnew-modal.modal{
    --bs-modal-margin: none !important;
    display: flex !important;
    align-items: center !important;
}
.heybotnew-modal.theme-light>.modal-dialog>.modal-content{
    background: white !important;
    color: black !important;
}
.heybotnew-modal.theme-dark>.modal-dialog>.modal-content{
    background: #362f2e !important;
    color: white !important;
}
.modal-backdrop{
    --bs-backdrop-opacity: 0.8 !important;
}
.DYXVr{
    max-width: 100% !important;
    height: 10rem !important;
    border: 2px solid #362f2e !important;
    flex-direction: column;
}
.DYXVr>svg{
    margin-top: 2rem;
}
.fDoQYg{
    flex-direction: column;
    padding-bottom: 2rem;
}
.sc-fFGjHI.cgTcOH{
    font-size: medium;
    color: #362f2e;
    font-weight: 600;
}
.file-types{
    max-width: none !important;
    text-align: center !important;
}
.embed-shadow{
    box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.1);
}
.heybotnew-embed{
    height: 100vh;
}
.heybotnew-preview{
    height: 20rem;
}
.heybotnew-embed-image{
   width: 2rem;
   height: 2rem;
}
.heybotnew-embed-chat{
    background-color: #DCF6FF;
 }
 .error-msg{
    border-radius: 0.6rem;
    background-color: rgba(255, 88, 88,0.6);
    color: #FFF0EE;
 }
#kbase-loader {
    overflow: visible;
    width: 5rem;
}
#kbase-loader g {
    animation: slide 2s linear infinite;
}
#kbase-loader g:nth-child(2) {
    animation-delay: 0.5s;
}
#kbase-loader g:nth-child(2) path {
    animation-delay: 0.5s;
    stroke-dasharray: 0px 158px;
    stroke-dashoffset: 1px;
}
#kbase-loader path {
    stroke: url(#gradient);
    stroke-width: 20px;
    stroke-linecap: round;
    fill: none;
    stroke-dasharray: 0 157px;
    stroke-dashoffset: 0;
    animation: escalade 2s cubic-bezier(0.8, 0, 0.2, 1) infinite;
}
.heybotnew-scroll{
    overflow-y: scroll;
    scrollbar-width: none;
}
.heybotnew-scroll::-webkit-scrollbar {
    width: 0;
}
.progress{
    height: 0.5rem !important;
}

.suggestion-scroll{
    padding-bottom: 0.2rem;

}
.suggestion-scroll::-webkit-scrollbar {
    width: 2.5px;
    height: 2.5px;
  }
  
  .suggestion-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  
  .suggestion-scroll::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(48, 48, 48, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  
 .suggestion-scroll::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.3);
  }
  
@keyframes slide {
    0% {
        transform: translateY(-50px);
   }
    100% {
        transform: translateY(50px);
   }
}
@keyframes escalade {
    0% {
        stroke-dasharray: 0 157px;
        stroke-dashoffset: 0;
   }
    50% {
        stroke-dasharray: 157px 157px;
        stroke-dashoffset: 0;
   }
    100% {
        stroke-dasharray: 157px 157px;
        stroke-dashoffset: -156px;
   }
}

.heybotnew-general-btn>.accordion-button{
    padding: 0 !important;
}
.button-border-wrap {
    width: 15rem;
    background: linear-gradient(#ff5858,#f857a6);
    padding: 5px;
    border-radius: 0.8rem;
}
.customize-text{
    background: -webkit-linear-gradient(#ff5858,#f857a6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.customize-button {
    background: white;
    border-radius: 0.6rem;
}
.heybotnew-suggestion{
    cursor: pointer;
    border-radius: 0.75rem;
    opacity: 0.8;
    font-size: 0.875 !important;
    padding: 10px 12px;
    transition: opacity 300ms;
}
.heybotnew-suggestion:hover{
    opacity: 1;
}
.heybotnew-answer{
    white-space: pre-wrap;
    word-break: break-word;
    font-size: 1rem;
    font-weight: 500;

}
.heybotnew-general-btn.heybotnew-accordion-header>button{
    display: flex;
    justify-content: space-between;
}
.heybotnew-msg-container{
    max-width: 80%;
    padding: 10px 12px;

}
.heybotnew-input-container{
    max-width: 55rem;
}
.heybotnew-answer>p{
    margin: 0;
}
.heybotnew-welcome-title{
    font-weight: 700;
    font-size: 3rem;
    color: #362f2e;
}
.heybotnew-welcome-para{
    font-weight: 500;
    width: 60%;
}
.heybotnew-welcome-logo{
    height: 6rem;
}
.answer-typing>*:last-child::after {
    content: "";
    width: 0.5rem;
    height: 1rem;
    background: #fff;
    display: inline-block;
    margin-left: 0.2rem;
    margin-bottom: -0.2rem;
    animation: cursor-blink 1s steps(2) infinite;
}
@keyframes cursor-blink {
    0% {
        opacity: 0.4;
    }
    
}
.heybotnew-feature{
    background-color: #ABE4F8;
    color: #362f2e;
    font-weight: 500;
    min-height: 7rem;
    border-top-left-radius: 0;
    font-size: 0.875rem;
}
.heybotnew-feature-container{
    width: 70%;
}
.heybotnew-welcome-checkout{
    bottom: 6rem;
    right: 3rem;
    font-weight: 600;
}
.heybotnew-preview-image{
    max-height: 8rem !important;
}
.heybotnew-preview-card{
    max-width: 15rem;
}
.heybotnew-preview-title{
    font-size: 0.875rem !important;
    font-weight: 600 !important;
}
.heybotnew-preview-desc{
    font-size: 0.75rem;
}
.heybotnew-loader{
    height: 1.5rem !important;
    --bs-progress-bar-color:#DCF6FF!important;
    --bs-progress-bar-bg:#ABE4F8!important;
}
.heybotnew-pricing-box{
    border-radius: 22px;
    background-color: #DCF6FF;
    width: 15rem;
    height: 30rem;
    cursor: pointer;
}
.heybotnew-pricing-box:hover{
    scale: 1.05;
}
.heybotnew-pricing-toggle{

    border-radius: 0.6rem ;
    -webkit-box-shadow:  2px 2px 4px 2px rgba(55, 55, 55, 0.1);
    -moz-box-shadow:  2px 2px 4px 2px rgba(55, 55, 55, 0.1);
    box-shadow:  2px 2px 4px 2px rgba(55, 55, 55, 0.1);
}

.heybotnew-success-animation {
    width: 100px;
    display: block;
    margin: 40px auto 0;
  }
  
.heybotnew-success-path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    
}
.heybotnew-success-path.circle {
    -webkit-animation: dash .9s ease-in-out;
    animation: dash .9s ease-in-out;
  }
  .heybotnew-success-path.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash .9s .35s ease-in-out forwards;
    animation: dash .9s .35s ease-in-out forwards;
  }
  .heybotnew-success-path.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check .9s .35s ease-in-out forwards;
    animation: dash-check .9s .35s ease-in-out forwards;
  }
  
.heybotnew-success-answer {
    text-align: center;
    margin: 20px 0 60px;
    font-size: 1.25rem;
    color: #73AF55;    
}
.heybotnew-error-answer {
    text-align: center;
    margin: 20px 0 60px;
    font-size: 1.25rem;
    color: #D06079;
}
  

  
  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }


  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }

  .heybotnew-simple-embed{
    min-height: 30rem;
    max-width: 60%;
    border-radius: 24px;

  }
  
@media screen and (max-width:1000px){
    .heybotnew-main{
        width: 100% !important;
        height: 90vh !important;
    }
    .heybotnew-bg{
        height: 100%;
    }
    .heybotnew-welcome-logo{
        height: 2rem;
    }
    .heybotnew-welcome-title{
        font-size: 2rem;
        margin: 0;
    }
    .heybotnew-welcome-para{
        width: 100%;
        margin-top: 1rem;
        font-size: 0.875rem;
    }
    .heybotnew-feature-container{
        width: 100%;
    }
    .heybotnew-feature{
        width: 100%;
        font-size: 0.75rem;
        min-height: 5rem;

    }
}
@media screen and (max-width:700px){
    .heybotnew-navbar-container{
        height: auto;
    }
    .heybotnew-modal>.modal-dialog>.modal-content{
        height: auto !important;
    }
    .pricing-modal>.modal-dialog>.modal-content{
        height: auto !important;
    }
    .pricing-modal.modal{
        display: block !important;
    }
    .modal-dialog.modal-dialog-centered{
        width: 100%;
    }
     .heybotnew-msg-container{
        max-width: 100%;
    }
    .heybotnew-preview-card{
        max-width: 100%;
    }
    .heybotnew-answer{
        font-size: 0.875rem;
    }
    .heybotnew-suggestion{
        padding: 5px 6px;
        font-size: 0.75 !important;

    
    }
    .heybotnew-simple-embed{
        max-width: 100%;
    }
}

.terms-privacy-link{
    color: black  !important;
    text-decoration: none  !important;
    font-size: 14px !important;
    transition: text-decoration 300ms;
}

.terms-privacy-link:hover{
    text-decoration: underline !important;
}

.integrations-icon-bg{
border: 1px solid #cccccc !important;
border-radius: 12px !important;
background: white  !important;
transition: background 300ms  !important;
cursor:pointer !important;
}

.integrations-icon-bg:hover{
background:#f0f0f0 !important;
}

.integration-remove-text{
    font-size: 13px !important;
    color: red !important;
    transition: text-decoration 300ms !important;
    cursor: pointer !important;
}

.integration-remove-text:hover{
    text-decoration: underline !important;
}
